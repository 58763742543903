<template>
  <vx-card class="ml-0 md:ml-10">
    <div class="w-full lg:w-1/2">
    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <template v-if="dataImg">
        <vs-avatar :src="dataImg" size="128px"/>

        <!-- Image upload Buttons -->
        <div class="modify-img flex mt-5 w-full">
          <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.updateImgInput.click()" class="mx-2">Update Image</vs-button>
          <vs-button @click="dataImg = null" class="mx-2" color="danger">Remove Image</vs-button>
        </div>
      </template>

      <div class="upload-img mt-5" v-if="!dataImg">
        <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
        <vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
      </div>
    </div>

    <!-- Info -->
    <vs-input class="w-full mb-base" label-placeholder="Full Name" v-model="fullName"></vs-input>
    <vs-input class="w-full" label-placeholder="Email" v-model="email"></vs-input>

    <!--<vs-alert icon-pack="feather" icon="icon-info" class="h-full my-4" color="warning">
        <span>Your account is not verified. <a href="#" class="hover:underline">Resend Confirmation</a></span>
    </vs-alert>-->

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end mt-5">
      <vs-button class="ml-auto" @click="submitData" :disabled="!isFormValid">Save Changes</vs-button>
      <vs-button class="ml-4" color="danger">Reset</vs-button>
    </div>
    </div>
  </vx-card>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    props: ['apiRoute'],
    data() {
      return {
        image: [],
        fullName: this.$store.state.AppActiveUser.fullName,
        email: this.$store.state.AppActiveUser.email,
        dataImg: this.$store.state.AppActiveUser.profileImage || null,
        userId: this.$store.state.AppActiveUser._id
      }
    },
    methods: {
      ...mapActions("teacher", [
        "updateProfile"
      ]),
      updateCurrImg(input) {
        if (input.target.files && input.target.files[0]) {
          this.image = input.target.files[0];
          var reader = new FileReader()
          reader.onload = e => {
            this.dataImg = e.target.result
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {

            let data = new FormData();
            data.append('fullName', this.fullName);
            data.append('email', this.email);
            data.append('image', this.image);
            data.append('userId', this.userId);

            this.$vs.loading()
            if (this.apiRoute) {

              this.updateProfile(data).then((response) => {
                this.$vs.notify({
                  title: 'Success',
                  text: 'Profile Updated Successfully.',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
                this.$vs.loading.close()
              })
                .catch(error => {
                  if (error.response.status === 422) {
                    this.$vs.notify({
                      title: 'Failure',
                      text: "Email already exists.",
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                    })
                  } else {
                    this.$vs.notify({
                      title: 'Failure',
                      text: "Update Profile Failed",
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                    })
                  }
                  this.$vs.loading.close()
                })
            } else {
              this.$store.dispatch('adminUser/updateProfile', data)
                .then((response) => {
                  this.$vs.notify({
                    title: 'Success',
                    text: 'Profile Updated Successfully.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  })
                  this.$vs.loading.close()
                })
                .catch(error => {
                  if (error.response.status === 422) {
                    this.$vs.notify({
                      title: 'Failure',
                      text: "Email already exists.",
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                    })
                  } else {
                    this.$vs.notify({
                      title: 'Failure',
                      text: "Update Profile Failed",
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                    })
                  }
                  this.$vs.loading.close()
                })
            }

          }
        })
      }
    },
    created() {
      console.log(this.$store.state.AppActiveUser, "s");
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser
      },
      isFormValid() {
        return !this.errors.any() && this.fullName && this.email
      }
    }

  }
</script>
