<template>
  <vx-card>
    <div class="w-full md:w-1/2">
    <vs-input class="w-full mb-base" label-placeholder="Old Password" type="password" v-model="currentPassword"/>
    <vs-input class="w-full mb-base" label-placeholder="New Password" type="password" v-model="newPassword"/>
    <vs-input class="w-full mb-base" label-placeholder="Confirm Password" type="password"
              v-model="confirmPassword"/>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end mt-5">
      <vs-button class="ml-auto" :disabled="!validateForm" @click="updatePassword">Save Changes</vs-button>
      <vs-button class="ml-4" color="danger">Reset</vs-button>
    </div>
  </div>
  </vx-card>
</template>

<script>
  import {mapActions} from "vuex";
  import firebase from "firebase";

  export default {
    props: ['apiRoute'],
    data() {
      return {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }
    },
    methods: {
      ...mapActions("teacher", [
        "changePassword"
      ]),
      updatePassword() {

        /*const headers = {
            'Content-Type': 'application/json',
            'token': localStorage.getItem(`${VUE_APP_ACCESS_TOKEN_NAME}accessToken`)
        }*/

        let data = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword,
          userID: this.$store.state.AppActiveUser.uid
        }

        this.$validator.validate().then(valid => {
          if (valid) {

            this.$vs.loading()
            if (this.apiRoute) {
              this.changePassword(data)
                .then((response) => {
                  let self = this;
                  self.currentPassword = self.newPassword = self.confirmPassword = "";
                  self.$vs.notify({
                    title: 'Success',
                    text: 'Password Changed.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  })
                  self.$vs.loading.close()
                  setTimeout(function () {
                    self.logout();
                  }, 300)

                })
                .catch(error => {
                  if (error.response.status === 422) {
                    this.$vs.notify({
                      title: 'Error',
                      text: error.response.data.message,
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'warning'
                    })
                  }
                  if (error.response.status === 410) {
                    this.$vs.notify({
                      title: 'Error',
                      text: error.response.data.message,
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'warning'
                    })
                  }

                  this.$vs.loading.close()

                })
            } else {

              this.$store.dispatch('adminUser/changePassword', data)
                .then((response) => {
                  this.currentPassword = this.newPassword = this.confirmPassword = "";
                  this.$vs.notify({
                    title: 'Success',
                    text: 'Password Changed.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  })
                  this.$vs.loading.close()

                  let self = this;
                  setTimeout(function () {
                    self.logout();
                  }, 300)

                })
                .catch(error => {
                  if (error.response.status === 422) {
                    this.$vs.notify({
                      title: 'Error',
                      text: error.response.data.message,
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'warning'
                    })
                  }
                  if (error.response.status === 410) {
                    this.$vs.notify({
                      title: 'Error',
                      text: error.response.data.message,
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'warning'
                    })
                  }

                  this.$vs.loading.close()

                })
            }


          }
        })
      },
      logout() {

        // if user is logged in via auth0
        if (this.$auth.profile) this.$auth.logOut();

        // if user is logged in via firebase
        const firebaseCurrentUser = firebase.auth().currentUser

        if (firebaseCurrentUser) {
          firebase.auth().signOut().then(() => {
            this.$router.push('/login').catch(() => {
            })
          })
        }

        this.$acl.change('guest')


        // If JWT login
        if (localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`)) {
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`)
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}refreshToken`)
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}firstName`)
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}tokenExpiry`)
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`)
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}loggedIn`)

          if (this.activeUserInfo.userType == 'superAdmin' || this.activeUserInfo.userType == 'admin') {
            this.$router.push('/admin/login').catch(() => {
            })
          } else {
            this.$router.push('/login').catch(() => {
            })
          }
        }

        // Change role on logout. Same value as initialRole of acj.js

        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`)


        // This is just for demo Purpose. If user clicks on logout -> redirect
        if (this.activeUserInfo.userType == 'superAdmin' || this.activeUserInfo.userType == 'admin') {
          this.$router.push('/admin/login').catch(() => {
          })
        } else {
          this.$router.push('/login').catch(() => {
          })
        }


      },

    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.currentPassword != '' && this.newPassword != '' && this.confirmPassword != '' && this.newPassword == this.confirmPassword;
      },
      activeUserInfo() {
        return this.$store.state.AppActiveUser
      },
    }
  }
</script>
<style lang="scss" scoped>
  .vs-con-input-label.is-label-placeholder.isFocus
  .vs-input--placeholder,
  .vs-input--input.hasIcon:focus + .vs-input--placeholder,
  .vs-input--input.hasValue + .vs-placeholder-label,
  .vs-input--input:focus + .vs-placeholder-label {
    padding-left: 10px !important;
  }
</style>
