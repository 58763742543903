<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none ml-10" id="profile-tabs" :key="isSmallerScreen" :value="activeTab">
    <a href="#"  ref="asd"></a>

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'General' : ''" class="p-0 w-auto mr-10">
      <div class="tab-general">
        <user-settings-general />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Change Password' : ''" class="p-0 w-auto mr-10">
      <div class="tab-change-pwd">
        <user-settings-change-password />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Connect Xero' : ''" class="p-0 w-auto mr-10">
      <div class="tab-connect-xero">
        <user-settings-connect-with-xero />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import UserSettingsGeneral from "./UserSettingsGeneral.vue"
import UserSettingsChangePassword from "./UserSettingsChangePassword.vue"
import UserSettingsConnectWithXero from "./UserSettingsConnectWithXero.vue"

export default {
  components: {
    UserSettingsGeneral,
    UserSettingsChangePassword,
    UserSettingsConnectWithXero
  },
  data() {
    return {
      activeTab: 0
    }
  },
  created() {
    if ( this.$route.query.tab !== undefined && this.$route.query.tab === 'xero' ) {
      this.activeTab = 2;
      if ( this.$route.query.connection !== undefined && this.$route.query.connection === 'success' ) {
        this.$store
          .dispatch("auth/checkXeroStatus")
          .then((response) => {
            this.$vs.notify({
              title: 'Connected to xero.',
              text: "You have successfully connected to xero.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Failure',
              text: "Unable to connect to xero.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          });
      }
      else if( this.$route.query.connection !== undefined && this.$route.query.connection === 'failure' ) {
        this.$vs.notify({
          title: 'Failure',
          text: "Unable to connect to xero.",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      }
    }
  },
  mounted() {
    this.$refs.asd.click();
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
  .line-vs-tabs{
    display: none;
  }
  .con-ul-tabs {
    padding-right: 20px !important;
    .ul-tabs {
      border-right: none;
    }
    .vs-tabs--li.activeChild {
      .vs-tabs--btn {
        padding: 0 !important;
        span {
          top: -1px;
          padding: 0 0 0 5px;
        }
        &:after {
          display: none !important;
        }
      }
    }
    .vs-tabs--btn {
      padding: 0 !important;
    }
  }
}

.vs-con-input-label.is-label-placeholder.isFocus
.vs-input--placeholder,
.vs-input--input.hasIcon:focus+.vs-input--placeholder,
.vs-input--input.hasValue+.vs-placeholder-label,
.vs-input--input:focus+.vs-placeholder-label {
  padding-left: 10px!important;
}
</style>
