<template>
  <vx-card no-shadow>

    <div class="flex flex-wrap justify-between items-center mb-8">
      <div>
        <p v-if="activeUserInfo.isConnectedToXero">
          Account is connected with Xero.
          <i class="material-icons" :style="{color: '#74CAC2'}">
            check_circle
          </i>
        </p>
        <p v-else>
          Account is not connected to Xero.
        </p>
      </div>
      <div v-if="activeUserInfo.isConnectedToXero">
      </div>
      <div v-else>
        <vs-button
          color="#00aaff"
          icon-pack="feather"
          icon="icon-xero"
          class="mb-6 ml-auto"
          @click.stop.prevent="connectXeroInPopUp"
          :disabled="isDisabled"
        >Connected To Xero
        </vs-button>
      </div>
    </div>

  </vx-card>
</template>

<script>
export default {
  name: "UserSettingsConnectWithXero",
  data() {
    return {
      isDisabled: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    connectXeroInPopUp() {
      this.$vs.loading()
      this.isDisabled = true;
      window.open(`${process.env.VUE_APP_DOMAIN}/api/v1/xero/connect`, '_SELF')
    }
  }
}
</script>

<style scoped>

</style>
